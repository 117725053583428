module.exports = [{
      plugin: require('/Users/julien/Development/Projects/jesterking/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-52749013-2"},
    },{
      plugin: require('/Users/julien/Development/Projects/jesterking/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/julien/Development/Projects/jesterking/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
